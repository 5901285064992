import Swiper from "swiper";

export default () => {
    let mapInitStatus = false;
    preloaderInit();
    heroSliderInit();
    worksSliderInit();
    getYoutubeIfreme();
    compareTabs();
    popupSlidersInit();
    worksTabs();
    closeWorksPopup();
    fixedButtonsLogic();
    formPopup();
    closeFormPopup();
    certificatesSliders();
    subnavMob();
    mobNav();
    anchorScroll();
    mapInitChecker();

    window.addEventListener('scroll', function () {
        mapInitChecker();
    });

    setTimeout(function () {
        loadImgOnScroll();
    },300);

    function heroSliderInit() {
        const sliderEl = document.querySelector(".hero__slider");

        if (sliderEl) {
            const slider = new Swiper(sliderEl, {
                wrapperClass: "hero__swiper-wrapper",
                slideClass: "hero__slide",
                loop: true,
                speed: 800,
                spaceBetween: 25,
                slidesPerView: 1,
                slideToClickedSlide: true,
                touchStartPreventDefault: false,
                breakpoints: {},
                navigation: {
                    nextEl: '.hero__slider-btn--next',
                    prevEl: '.hero__slider-btn--prev',
                },
            });
        }
    }

    function worksSliderInit() {
        const sliderEl = document.querySelector(".works__slider");

        if (sliderEl) {
            let sliderWrap = sliderEl.closest('.works__inner');
            const slider = new Swiper(sliderEl, {
                wrapperClass: "works__swiper-wrapper",
                slideClass: "works__slide",
                speed: 800,
                loop: true,
                spaceBetween: 25,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchStartPreventDefault: false,
                breakpoints: {
                    400: {
                        slidesPerView: 1,
                    }
                },
                navigation: {
                    nextEl: '.works__slider-btn.slider-btn--next',
                    prevEl: '.works__slider-btn.slider-btn--prev',
                },
                on: {
                    init: function () {
                        worksSliderCounter(sliderWrap)
                    },
                    transitionEnd: function () {
                        worksSliderCounter(sliderWrap)
                    },
                },
            });
        }
    }

    function worksSliderCounter(currentWrap) {
        let offer = currentWrap.querySelector('.works__slider-counter');
        let totalSlides = currentWrap.querySelectorAll('.works__slide:not(.swiper-slide-duplicate)').length;
        let slideActive = currentWrap.querySelector('.swiper-slide-active');
        let realIndex;
        if (slideActive) {
            realIndex = slideActive.getAttribute('data-swiper-slide-index');
        }
        if (typeof realIndex === 'undefined' && slideActive) {
            realIndex = slideActive.index();
        }
        offer.innerHTML = '<span>' + (Number(realIndex) + 1) + '</span> / ' + (totalSlides);
    }

    function closeWorksPopup() {
        let closeBtns = document.querySelectorAll('.w-popup__close');
        let wPopups = document.querySelectorAll('.w-popup');
        if (closeBtns) {
            closeBtns.forEach((btn) => {
                btn.addEventListener('click', function () {
                    wPopups.forEach((popup) => {
                        popup.classList.remove('active');
                        scrollStopOff();
                    })
                });
            })
        }
    }

    function popupSlidersInit() {
        const sliderElements = document.querySelectorAll(".w-popup__slider");

        if (sliderElements) {
            sliderElements.forEach((sliderEl) => {
                let sliderWrap = sliderEl.closest('.w-popup');
                let currentPopup = sliderWrap.getAttribute('id');
                let currentPopupNum = parseInt(currentPopup.match(/\d+/));
                let currentCloseBtn = sliderWrap.querySelector('.w-popup__close');
                let nextBtn = sliderWrap.querySelector('.slider-btn--next');
                let prevBtn = sliderWrap.querySelector('.slider-btn--prev');
                let slider = new Swiper(sliderEl, {
                    wrapperClass: "w-popup__swiper-wrapper",
                    slideClass: "w-popup__slide",
                    loop: true,
                    speed: 800,
                    spaceBetween: 25,
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    touchStartPreventDefault: false,
                    breakpoints: {},
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                    on: {
                        init: function () {
                            popupSliderCounter(sliderWrap, this);
                        },
                        transitionEnd: function () {
                            popupSliderCounter(sliderWrap, this);
                        },
                    },
                });
                // let last = false;
                // let first = false;
                // nextBtn.addEventListener('click', function () {
                //     let totalSlides = sliderWrap.querySelectorAll('.w-popup__slide:not(.swiper-slide-duplicate)').length;
                //     let realIndex = slider.realIndex;
                //     first = false;
                //     if (last) {
                //         console.log('changeSlideNow');
                //         nextPopup();
                //     }
                //     if ((realIndex + 1) === totalSlides) {
                //         last = true;
                //     }
                // });
                // prevBtn.addEventListener('click', function () {
                //     let realIndex = slider.realIndex;
                //     last = false;
                //     if (first) {
                //         console.log('changeSlideNow');
                //         prevPopup()
                //     }
                //     if (realIndex === 0) {
                //         first = true;
                //     }
                // });
                //
                // function nextPopup() {
                //     console.log('1');
                //     if (currentPopupNum !== 8) {
                //         console.log('2');
                //         closeWorksPopups();
                //         let nextPopup = document.getElementById(`popup_${currentPopupNum+1}`);
                //         if (nextPopup) {
                //             setTimeout(() => {
                //                 nextPopup.classList.add('active');
                //             }, 300);
                //         }
                //     }
                // }
                //
                // function prevPopup() {
                //     if (currentPopupNum !== 1) {
                //         closeWorksPopups();
                //         let prevPopup = document.getElementById(`popup_${currentPopupNum-1}`);
                //         if (prevPopup) {
                //             setTimeout(() => {
                //                 prevPopup.classList.add('active');
                //             }, 300);
                //         }
                //     }
                // }
                //
                // function closeWorksPopups() {
                //     let wPopups = document.querySelectorAll('.w-popup');
                //     wPopups.forEach((popup) => {
                //         popup.classList.remove('active');
                //         scrollStopOff();
                //     })
                // }
            });
        }
    }

    function popupSliderCounter(currentWrap, slider) {
        let offers = currentWrap.querySelectorAll('.w-popup__counter');
        let totalSlides = currentWrap.querySelectorAll('.w-popup__slide:not(.swiper-slide-duplicate)').length;
        let slideActive = currentWrap.querySelector('.swiper-slide-active');
        let realIndex;
        if (slideActive) {
            realIndex = slideActive.getAttribute('data-swiper-slide-index');
            // realIndex = slider.realIndex;
        }
        if (typeof realIndex === 'undefined' && slideActive) {
            realIndex = slideActive.index();
        }
        offers.forEach((offer) => {
            offer.innerHTML = '<span>' + (Number(realIndex) + 1) + '</span> / ' + (totalSlides);
        });
    }

    function getYoutubeIfreme() {
        let videoFrame = document.querySelectorAll('.w-video__frame-wrap');
        videoFrame.forEach((item) => {
            let youtubeId = item.getAttribute('data-youtube-id');
            item.addEventListener('click', function () {
                if (youtubeId) {
                    item.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + youtubeId + '?autoplay=1" frameborder="0" allow="autoplay" allowfullscreen></iframe>';
                }
            });
        })
    }

    function mapInit() {
        if (mapInitStatus) {
            return;
        }
        mapInitStatus = true;

        let map = document.getElementById('map');
        let locations = [
            ['G-tuning', 51.47013879459878, 31.301649131629837, 'вулиця, Київське шосе, 5, Чернігів, Чернігівська область, 14000'],
        ];

        // let mapStyles = [
        //     {
        //         "featureType": "all",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#ffffff"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "all",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "gamma": 0.01
        //             },
        //             {
        //                 "lightness": 20
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "all",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "saturation": -31
        //             },
        //             {
        //                 "lightness": -33
        //             },
        //             {
        //                 "weight": 2
        //             },
        //             {
        //                 "gamma": 0.8
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "all",
        //         "elementType": "labels.icon",
        //         "stylers": [
        //             {
        //                 "visibility": "off"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative.locality",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "color": "#050505"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative.locality",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#fef3f3"
        //             },
        //             {
        //                 "weight": "3.01"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative.neighborhood",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "color": "#0a0a0a"
        //             },
        //             {
        //                 "visibility": "off"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative.neighborhood",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#fffbfb"
        //             },
        //             {
        //                 "weight": "3.01"
        //             },
        //             {
        //                 "visibility": "off"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "landscape",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "lightness": 30
        //             },
        //             {
        //                 "saturation": 30
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "poi",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "saturation": 20
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "poi.attraction",
        //         "elementType": "labels.icon",
        //         "stylers": [
        //             {
        //                 "visibility": "off"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "poi.park",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "lightness": 20
        //             },
        //             {
        //                 "saturation": -20
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "lightness": 10
        //             },
        //             {
        //                 "saturation": -30
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "saturation": 25
        //             },
        //             {
        //                 "lightness": 25
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#686868"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#292929"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#202020"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#ffffff"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "labels.icon",
        //         "stylers": [
        //             {
        //                 "visibility": "simplified"
        //             },
        //             {
        //                 "hue": "#0006ff"
        //             },
        //             {
        //                 "saturation": "-100"
        //             },
        //             {
        //                 "lightness": "13"
        //             },
        //             {
        //                 "gamma": "0.00"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.arterial",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#686868"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.arterial",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "visibility": "off"
        //             },
        //             {
        //                 "color": "#8d8d8d"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.arterial",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#353535"
        //             },
        //             {
        //                 "lightness": "6"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.arterial",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "weight": "3.45"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.local",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "color": "#686868"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.local",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "lightness": "2"
        //             },
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#999898"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.local",
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "color": "#000000"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.local",
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#faf8f8"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "transit.line",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#000000"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "water",
        //         "elementType": "all",
        //         "stylers": [
        //             {
        //                 "lightness": -20
        //             }
        //         ]
        //     }
        // ];

        if (map) {
            let myOptions = {
                center: new google.maps.LatLng(51.47013879459878, 31.301649131629837),
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                // styles: mapStyles,
            };
            map = new google.maps.Map(document.getElementById('map'), myOptions);

            setMarkers(map, locations);
        }

    }

    function mapInitChecker() {
        let windowHeight = document.documentElement.clientHeight;
        let map = document.getElementById('map');
        const mapTop = map.getBoundingClientRect().top;
        if (mapTop <= windowHeight) {
            mapInit();
        }
    }

    function setMarkers(map, locations) {
        let marker, i, mark_position;
        for (i = 0; i < locations.length; i++) { // Проходимся по нашему массиву с марками

            let title = locations[i][0];
            let lat = locations[i][1];
            let long = locations[i][2];
            let text = locations[i][3];

            mark_position = new google.maps.LatLng(lat, long); // Создаем позицию для отметки

            marker = new google.maps.Marker({ // Что будет содержаться в отметке
                map: map, // К какой карте относиться отметка
                title: title, // Заголовок отметки
                position: mark_position, // Позиция отметки
                animation: google.maps.Animation.DROP, // Анимация
                icon: '' // Можно поменять иконку, если оставить пустые скобки, то будет оригинальная иконка
            });

            var content = '<div class="info-block"><h3>' + title + '</h3><b>' + "Address: </b>" + text + '</div>';
            var infowindow = new google.maps.InfoWindow();

            google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
                return function () {
                    infowindow.setContent(content);
                    infowindow.open(map, marker);
                };
            })(marker, content, infowindow));
        }
    }

    function worksTabs() {
        let section = document.querySelector('.w-popups');

        if (section) {
            let tabsBtns = document.querySelectorAll('.works__slide');
            tabsBtns.forEach((popupTrigger) => {
                popupTrigger.addEventListener('click', function () {
                    let dataPopup = popupTrigger.getAttribute('data-popup');
                    let currentPopup = document.getElementById(dataPopup);
                    if (currentPopup) {
                        currentPopup.classList.add('active');
                        scrollStopOn();
                    }
                });
            })
        }
    }

    function compareTabs() {
        let section = document.querySelector('.compare');
        if (section) {
            let tabs = section.querySelectorAll('.compare__tab');
            let tabsBtns = section.querySelectorAll('.compare__nav-item');
            // tabs.forEach((tabItem) => {
            //     tabItem.classList.add('compare__tab--init');
            // });
            tabsBtns.forEach((tabBtn) => {
                tabBtn.addEventListener('click', function () {
                    if (!tabBtn.classList.contains('compare__nav-item--active')) {
                        let dataTab = tabBtn.getAttribute('data-tab');
                        tabsBtns.forEach((item) => {
                            let itemDataTab = item.getAttribute('data-tab');
                            if (itemDataTab === dataTab) {
                                item.classList.add('compare__nav-item--active');
                            } else {
                                item.classList.remove('compare__nav-item--active');
                            }
                        });
                        tabs.forEach((item2) => {
                            let currentTabId = item2.getAttribute('id');
                            if (currentTabId === dataTab) {
                                item2.classList.add('compare__tab--active');
                            } else {
                                item2.classList.remove('compare__tab--active');
                            }
                        });
                    }
                })
            })
        }
    }

    function fixedButtonsLogic() {
        let button = document.querySelector('.main-btn');
        let contactButtons = document.querySelector('.contact-buttons');
        button.addEventListener('click', function (e) {
            e.preventDefault();
            contactButtons.classList.toggle('active');
            button.classList.toggle('active');
        });
    }

    function formPopup() {
        let popup = document.querySelector('.form-popup');
        let popupTriggers = document.querySelectorAll('.cp-trigger');
        if (popupTriggers) {
            popupTriggers.forEach((tr) => {
                tr.addEventListener('click', function (e) {
                    e.preventDefault();
                    popup.classList.add('active');
                    scrollStopOn();
                })
            });
        }
    }

    function closeFormPopup() {
        let closeBtn = document.querySelector('.form-popup__close');
        let fPopup = document.querySelector('.form-popup');
        if (closeBtn) {
            closeBtn.addEventListener('click', function () {
                fPopup.classList.remove('active');
                scrollStopOff();
            });
        }
    }

    function certificatesSliders() {
        const sliderEl = document.querySelector(".certificates__slider");
        const sliderElExtra = document.querySelector(".certificates__slider-extra");
        if (sliderEl && sliderElExtra) {
            let sliderWrap = sliderEl.closest('.certificates__col--slider');

            const slider2 = new Swiper(sliderElExtra, {
                wrapperClass: "certificates__swiper-wrapper-extra",
                slideClass: "certificates__slide-extra",
                speed: 800,
                spaceBetween: 14,
                direction: 'vertical',
                slidesPerView: 3,
                slideToClickedSlide: true,
                touchStartPreventDefault: false,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                breakpoints: {
                    600: {
                        direction: 'horizontal'
                    }
                },
            });

            const slider = new Swiper(sliderEl, {
                wrapperClass: "certificates__swiper-wrapper",
                slideClass: "certificates__slide",
                speed: 800,
                spaceBetween: 20,
                slidesPerView: 1,
                slideToClickedSlide: true,
                touchStartPreventDefault: false,
                breakpoints: {},
                navigation: {
                    nextEl: '.certificates__slider-btn.slider-btn--next',
                    prevEl: '.certificates__slider-btn.slider-btn--prev',
                },
                thumbs: {
                    swiper: slider2
                }
            });
        }
    }

    function preloaderInit() {
        let startValue = 0;

        function progress() {
            startValue = startValue + 100 / 25;
            let progress = document.querySelector('.preloader__progress-inner');
            if (startValue <= 100) {
                progress.style.width = startValue + '%';
            } else {
                clearInterval(progressInterval);
                removePreloader();
            }
        }

        let progressInterval = setInterval(progress, 50);

        function removePreloader() {
            let preloader = document.querySelector('.preloader');
            preloader.style.opacity = 0;
            scrollStopOff();
            setTimeout(() => {
                preloader.style.display = 'none';
            }, 400);
        }
    }

    function subnavMob() {
        let snTrigger = document.querySelector('.sub-nav-here--mob');
        if (snTrigger) {
            snTrigger.addEventListener('click', function (e) {
                e.preventDefault();
                let helper = snTrigger.closest('.sn-helper');
                if (helper) {
                    helper.classList.toggle('active');
                }
            });
        }
    }

    function mobNav() {
        let navTrigger = document.querySelector('.nav-trigger');
        let closeBtn = document.querySelector('.header-mob-nav__close');
        let mobNav = document.querySelector('.header-mob-nav');
        if (navTrigger) {
            navTrigger.addEventListener('click', function (e) {
                e.preventDefault();
                mobNav.classList.add('active');
                scrollStopOn();
            });
            closeBtn.addEventListener('click', function (e) {
                e.preventDefault();
                mobNav.classList.remove('active');
                scrollStopOff();
            });
        }
    }

    function scrollStopOn() {
        let body = document.querySelector('body');
        let html = document.querySelector('html');
        body.classList.add('scrollStop');
        html.classList.add('scrollStop');
    }

    function scrollStopOff() {
        let body = document.querySelector('body');
        let html = document.querySelector('html');
        body.classList.remove('scrollStop');
        html.classList.remove('scrollStop');
    }

    function hideMobNav() {
        let mobNav = document.querySelector('.header-mob-nav');
        let body = document.querySelector('body');
        let html = document.querySelector('html');
        mobNav.classList.remove('active');
        body.classList.remove('scrollStop');
        html.classList.remove('scrollStop');
    }

    function anchorScroll() {
        const anchors = document.querySelectorAll('a[href*="#"]');
        anchors.forEach((anchor) => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                hideMobNav();
                const blockID = anchor.getAttribute('href').substr(1);
                let elemToScroll = document.getElementById(blockID);
                // let headerHeight = (window.innerWidth > 1024) ? 140 : 75;
                let headerHeight = 0;
                if (elemToScroll) {
                    let scrollToPosition = elemToScroll.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                    window.scrollTo({
                        behavior: 'smooth',
                        top: scrollToPosition,
                    });
                } else {
                    document.location = document.location.origin + '/#' + blockID;
                }
            });
        });
    }

    function loadImgOnScroll(){
        const images = document.querySelectorAll('.lazyImg2');
        images.forEach(item => {
                loadImage(item);
        });
        function loadImage(image) {
            image.src = image.getAttribute('data');
            image.classList.add('show');
        }
    }

    /*LazyLoad*/

    const images = document.querySelectorAll('.lazyImg');


    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    }

    function handleImg(myImg, observer) {
        myImg.forEach(myImgSingle => {
            // console.log(myImgSingle.intersectionRatio);
            if (myImgSingle.intersectionRatio > 0) {
                loadImage(myImgSingle.target);
            }
        })
    }

    function loadImage(image) {
        image.src = image.getAttribute('data');
        image.classList.add('show');
    }

    const observer = new IntersectionObserver(handleImg, options);

    images.forEach(img => {
        observer.observe(img);
    })


}
